import classNames from "classnames";
import Link from "next/link";
import { useSelector } from "react-redux";

import useDomainCountryCode from "hooks/useDomainCountryCode";
import { getCurrentDispensary } from "redux/selectors/dispensary";
import { dispensaryHref } from "utils/dispensaryUrls";
import { trackMenuEvent } from "utils/trackEvent";

const ShopAllButton: React.FC<{ className?: string }> = ({ className }) => {
  const { slug, retailType } = useSelector(getCurrentDispensary);
  const countryCode = useDomainCountryCode();

  const href = dispensaryHref({
    additionalPath: "/menu",
    countryCode,
    retailType,
    slug,
  });

  const trackButtonClick = () =>
    trackMenuEvent("Click", "Shop All Products Button");

  return (
    <div
      data-testid="shop-all-button"
      className={classNames("row px-md", className)}
    >
      <Link
        data-testid="shop-all-link"
        href={href}
        role="button"
        onClick={trackButtonClick}
        onKeyPress={trackButtonClick}
        className="button button--secondary"
      >
        shop all products
      </Link>
    </div>
  );
};

export default ShopAllButton;
