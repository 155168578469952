import { useContext } from "react";
import { useSelector } from "react-redux";

import { Placement } from "api/requests/getBrandPlacement";
import MenuContext from "context/MenuContext";
import { Deal } from "custom-types/Deals";
import { MedicalIdCondition } from "custom-types/MedicalIdCondition";
import { getCurrentDispensary } from "redux/selectors/dispensary";

import ActiveDealsCarousel from "components/Dispensary/ActiveDealsCarousel";
import CategoryCarousel from "components/Dispensary/CategoryCarousel";
import LastUpdatedTime from "components/Dispensary/LastUpdatedTime";
import MedicalInfo from "components/Dispensary/MedicalInfo";
import PhenotypeCarousel from "components/Dispensary/PhenotypeCarousel";
import ProductRecTrays from "components/Dispensary/ProductRecTrays";
import PromotedBrandCarousel from "components/Dispensary/PromotedBrandCarousel";
import SearchBar from "components/Dispensary/SearchBar";
import ShopAllButton from "components/Dispensary/ShopAllButton";
import StaffPicksCarousel from "components/Dispensary/StaffPicksCarousel";
import RecentlyViewedMenuItems from "components/Shared/RecentlyViewedMenuItems";

const PaidTierMenu: React.FC<{
  brandPlacement: Placement | null;
  deals: Deal[];
}> = ({ brandPlacement, deals }) => {
  const {
    selectors: { categories },
  } = useContext(MenuContext);

  const currentDispensary = useSelector(getCurrentDispensary);
  const isMedIdRequired =
    currentDispensary.orderMedIdCondition === MedicalIdCondition.Required;

  return (
    <div className="bg-white pt-xl">
      <div className="container">
        {isMedIdRequired && <MedicalInfo />}

        <SearchBar className="mb-sm mt-md" routeToMenu />

        <LastUpdatedTime className="mb-xl" />

        <div className="flex flex-col gap-xl">
          <CategoryCarousel />

          <PromotedBrandCarousel brandPlacement={brandPlacement} />

          <RecentlyViewedMenuItems
            attributes={{ dispensarySlug: currentDispensary.slug }}
          />

          <ActiveDealsCarousel deals={deals} dealCardLinks />

          <StaffPicksCarousel />

          <ProductRecTrays categories={categories.slice(0, 1)} />

          <PhenotypeCarousel />

          <ProductRecTrays categories={categories.slice(1)} />

          <ShopAllButton />
        </div>
      </div>
    </div>
  );
};
export default PaidTierMenu;
