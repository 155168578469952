import React, { useEffect, useState } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import classnames from "classnames";
import { stringify } from "qs";
import { useSelector } from "react-redux";

import consumerApi from "api/services/consumer-api";
import { Action, Category, Label } from "constants/events";
import { MenuItem } from "custom-types/MenuItem";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getIsDualMenu } from "redux/selectors/dispensary";
import { getCoordinates } from "redux/selectors/location";
import { getUserId, getUserPrefersMedical } from "redux/selectors/user";
import { getRoundedDistance } from "utils/distance";
import logError from "utils/logError";
import { getSplitKey } from "utils/split/getSplitKey";
import { getUserHistoryItems, UserHistoryEntry } from "utils/userHistory";

import Carousel from "components/botanic/Carousel";
import MenuItemCard from "components/botanic/MenuItemCard";
import { MenuCardSkeleton } from "components/Shared/Skeletons";
import TrackImpression from "components/TrackImpression";

import { MenuItemCardActions } from "../MenuItemCarousel/MenuItemCardActions";

type Attributes = Pick<UserHistoryEntry, "attributes">;
type Props = Attributes & {
  excludedId?: number;
  className?: string;
  carouselClassName?: string;
  menuItemProps?: Partial<React.ComponentProps<typeof MenuItemCard>>;
  onCardClick?: (item: MenuItem) => void;
  onCardImpression?: (item: MenuItem) => void;
};

type MenuItemsResponse = {
  data: MenuItem[];
};

const ITEMS_PER_CAROUSEL = 8;

const RecentlyViewedMenuItems: React.FC<Props> = ({
  attributes,
  excludedId,
  className,
  carouselClassName,
  menuItemProps,
  onCardClick,
  onCardImpression,
}) => {
  const [itemsLoaded, setLoaded] = useState(false);
  const [recentMenuItems, setRecentMenuItems] = useState<MenuItem[]>([]);

  const coordinates = useSelector(getCoordinates);
  const countryCode = useDomainCountryCode();
  const userId = useSelector(getUserId);
  const isDualMenuDispensary = useSelector(getIsDualMenu);
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  const { publishEvent } = useEventTracker();

  useEffect(() => {
    const getMenuItems = async (menuItemIds: number[]) => {
      try {
        const queryString = stringify(
          {
            filter: {
              menu_item_id: menuItemIds,
            },
            ...coordinates,
          },
          { arrayFormat: "brackets" },
        );

        let {
          data: { data: menuItems },
        } = await consumerApi.get<MenuItemsResponse>(
          `/api/menu_items/v2?${queryString}`,
        );

        menuItems = menuItems.sort((a, b) => {
          return (
            menuItemIds.indexOf(a.menuItemId) -
            menuItemIds.indexOf(b.menuItemId)
          );
        });

        setRecentMenuItems(menuItems);
        setLoaded(true);
      } catch (e) {
        if (e.message && e.statusCode) {
          logError(e.message, {
            functionName: "RecentlyViewedMenuItems getMenuItems",
            service: "consumer",
            statusCode: e.statusCode,
          });
        } else {
          logError(
            "error processing results in RecentlyViewedMenuItems useEffect",
          );
        }

        setLoaded(true);
      }
    };

    const fetchRecentlyViewed = async () => {
      const getRecentlyViewedMenuItemIds = async () => {
        const entries = await getUserHistoryItems(
          "menu-item",
          attributes,
          ITEMS_PER_CAROUSEL + 1,
        );

        return entries
          .map((entry) => entry.resourceId)
          .filter((id) => id !== excludedId)
          .slice(0, 8);
      };

      const menuItemIds = await getRecentlyViewedMenuItemIds();

      if (!menuItemIds?.length) {
        setLoaded(true);
      } else {
        getMenuItems(menuItemIds);
      }
    };

    fetchRecentlyViewed();
  }, []);

  let filteredMenuItems;
  // Check for filtering med/rec items if isDualMenuDispensary is provided/true
  if (isDualMenuDispensary) {
    // If on a dual menu, only show menu items matching the user's current selected menu type
    filteredMenuItems = recentMenuItems.filter((menuItem) =>
      isDualMenuDispensary ? menuItem.medical && userPrefersMedical : true,
    );
  } else {
    filteredMenuItems = recentMenuItems;
  }

  if (itemsLoaded && !filteredMenuItems.length) {
    return null;
  }

  const trackRecentlyViewedClick = (
    label: string,
    { menuItemId, id, brand, strain, dispensary }: MenuItem,
  ) => {
    publishEvent({
      action: Action.click,
      brandId: brand?.id as number,
      category: Category.recentlyViewedMenuItems,
      dispensaryDistance: getRoundedDistance(
        dispensary.distanceMi,
        countryCode,
      ),
      dispensaryId: dispensary.id,
      label,
      menuItemId,
      productId: id,
      strainId: strain?.id as number,
      userId: userId as number,
    });
  };

  return (
    <div className={classnames(className)}>
      <Carousel
        className={classnames(carouselClassName)}
        title={
          <h2 className="heading--m font-extrabold">{"You recently viewed"}</h2>
        }
      >
        {itemsLoaded
          ? filteredMenuItems.map((menuItem) => (
              <TrackImpression
                key={menuItem.id}
                className="h-full"
                trackingFunction={() => onCardImpression?.(menuItem)}
              >
                <MenuItemCard
                  handleCardClick={() => {
                    trackRecentlyViewedClick(
                      Label.menuItemCardViewDetails,
                      menuItem,
                    );
                    !!onCardClick && onCardClick(menuItem);
                  }}
                  menuItem={menuItem}
                  showFulfillment={true}
                  {...menuItemProps}
                >
                  <MenuItemCardActions
                    menuItem={menuItem}
                    viewDetailsOnClick={() =>
                      trackRecentlyViewedClick(
                        Label.menuItemCardViewDetailsButton,
                        menuItem,
                      )
                    }
                    onReservation={() =>
                      sendClientSideSplitTrackEvent(
                        "webDispensaryMenuItem_addToBagClick_frontend",
                        getSplitKey(),
                      )
                    }
                  />
                </MenuItemCard>
              </TrackImpression>
            ))
          : new Array(4)
              .fill(" ")
              .map((_, ind) => (
                <MenuCardSkeleton key={ind} className="rounded shadow-low" />
              ))}
      </Carousel>
    </div>
  );
};

export default RecentlyViewedMenuItems;
