import { useEffect, useState } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import { trackEvent } from "@leafly-com/web-utils";
import { useSelector } from "react-redux";

import getMenuItems from "api/requests/getMenuItems";
import { CATEGORY_NAMES } from "constants/dispensary";
import { MenuItem } from "custom-types/MenuItem";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import {
  getCurrentDispensary,
  getIsDualMenu,
} from "redux/selectors/dispensary";
import { getUserPrefersMedical } from "redux/selectors/user";
import { dispensaryHref } from "utils/dispensaryUrls";
import { MENU_TITLES } from "utils/menuTypeUtils";
import { getSplitKey } from "utils/split/getSplitKey";

import Carousel from "components/botanic/Carousel";
import MenuCard from "components/Dispensary/MenuCard";
import SkeletonCarousel from "components/Dispensary/SkeletonCarousel";
import TrackImpression from "components/TrackImpression";

type Props = {
  categories: string[];
};

const ProductRecTrays: React.FC<Props> = ({ categories = [] }) => {
  const { slug: dispensarySlug, retailType } =
    useSelector(getCurrentDispensary);
  const isDualMenuDispensary = useSelector(getIsDualMenu);
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  const countryCode = useDomainCountryCode();

  const [itemsLoaded, setLoaded] = useState<boolean>(false);
  const [itemsByCategory, setItemsByCategory] = useState<
    {
      menuItems: MenuItem[];
      name: string;
    }[]
  >([]);

  const medRecPreference =
    userPrefersMedical !== undefined
      ? userPrefersMedical
        ? MENU_TITLES.med.shortTitle
        : MENU_TITLES.rec.shortTitle
      : undefined;

  useEffect(() => {
    if (categories.length && medRecPreference) {
      Promise.all(
        // Make menu item requests for each category
        categories.map((category) =>
          getMenuItems(dispensarySlug, {
            is_staff_pick: false,

            menu_type: isDualMenuDispensary ? medRecPreference : undefined,

            perPage: 8,

            product_category: [category],
            /**
             * Canadian brands are not currently offered the lite-amplification
             * product that drives this promotional placement, but there is not
             * currently a way to differentiate whether to show this placement
             * depending on whether a brand has been sold this functionality or
             * not. When the integration of brand accounts in salesforce is
             * complete, this can be removed.
             *
             * Tracking ticket: https://leafly.atlassian.net/browse/BRND-419
             */
            promotedSlots: countryCode === "CA" ? 0 : 1,
          }),
        ),
      )
        .then((menuItemsByCategory) => {
          // Map results to name/value objects for rendering
          const mappedMenuItemsByCategory = menuItemsByCategory.map(
            ({ menuItems }, i) => {
              return {
                menuItems,
                name: categories[i],
              };
            },
          );

          // Store items and set loaded to true
          setItemsByCategory(mappedMenuItemsByCategory);
          setLoaded(true);
        })
        .catch(() => {
          setLoaded(true);
        });
    }
  }, [medRecPreference, categories.length, dispensarySlug]);

  const trackRecTrayEvent = (label: string) => {
    trackEvent("Dispensary Menu Rec Tray", "Click", label);
  };

  if (!categories.length) {
    return null;
  }

  const splitKey = getSplitKey();

  return itemsLoaded ? (
    <>
      {itemsByCategory.map(({ name, menuItems = [] }) => {
        if (!menuItems.length) {
          return null;
        }

        const displayName = CATEGORY_NAMES[name] || name;

        const menuLink = dispensaryHref({
          additionalPath: `/menu?product_category[]=${name}`,
          countryCode,
          retailType,
          slug: dispensarySlug,
        });

        const trackCardClick = () => trackRecTrayEvent(displayName);
        const trackSeeAllClick = () =>
          trackRecTrayEvent(`${displayName} - show all`);

        return (
          <div key={name} data-testid="product-rec-tray">
            <Carousel
              seeMoreHref={menuLink}
              seeMoreOnClick={trackSeeAllClick}
              title={
                <h2
                  className="heading--m font-extrabold"
                  data-testid="section-header"
                >
                  {displayName}
                </h2>
              }
              data-testid={`${name}-carousel`}
            >
              {menuItems.map((item, i) => {
                if (item.promoted) {
                  const trackingCategory = "Dispensary Menu Rec Tray";
                  const trackingLabel = `${displayName} Lite-Amp Card`;
                  const trackingDimensions = {
                    brandId: item.brand?.id,
                    carouselSlot: i + 1,
                    dispensaryId: item.dispensaryId,
                    menuItemId: item.id,
                    productId: item.product?.id,
                  };

                  return (
                    <TrackImpression
                      category={trackingCategory}
                      action="impression"
                      className="h-full pb-sm"
                      customDimensions={trackingDimensions}
                      key={item.id}
                      label={trackingLabel}
                    >
                      <MenuCard
                        menuItem={item}
                        onClick={() => {
                          trackCardClick();
                          trackEvent(
                            trackingCategory,
                            "Click",
                            trackingLabel,
                            trackingDimensions,
                          );
                        }}
                        onAddToBagClick={() => {
                          trackEvent(
                            "Dispensary Menu Rec Tray",
                            "Click",
                            `${trackingLabel} ATB`,
                            trackingDimensions,
                          );
                          sendClientSideSplitTrackEvent(
                            "webDispensaryMenuItem_addToBagClick_frontend",
                            splitKey,
                          );
                        }}
                      />
                    </TrackImpression>
                  );
                } else {
                  return (
                    <MenuCard
                      menuItem={item}
                      onClick={trackCardClick}
                      key={item.id}
                      onAddToBagClick={() => {
                        sendClientSideSplitTrackEvent(
                          "webDispensaryMenuItem_addToBagClick_frontend",
                          splitKey,
                        );
                      }}
                    />
                  );
                }
              })}
            </Carousel>
          </div>
        );
      })}
    </>
  ) : (
    <>
      {categories.map((name) => {
        const displayName = CATEGORY_NAMES[name] || name;

        return (
          <div key={name} data-testid="product-rec-tray" className="mb-section">
            <SkeletonCarousel header={displayName} />
          </div>
        );
      })}
    </>
  );
};

export default ProductRecTrays;
